// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aboutus-js": () => import("./../../../src/pages/aboutus.js" /* webpackChunkName: "component---src-pages-aboutus-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-business-audit-js": () => import("./../../../src/pages/business-audit.js" /* webpackChunkName: "component---src-pages-business-audit-js" */),
  "component---src-pages-cloud-services-js": () => import("./../../../src/pages/cloud-services.js" /* webpackChunkName: "component---src-pages-cloud-services-js" */),
  "component---src-pages-contactus-2-js": () => import("./../../../src/pages/contactus2.js" /* webpackChunkName: "component---src-pages-contactus-2-js" */),
  "component---src-pages-contactus-js": () => import("./../../../src/pages/contactus.js" /* webpackChunkName: "component---src-pages-contactus-js" */),
  "component---src-pages-facilitation-services-js": () => import("./../../../src/pages/facilitation-services.js" /* webpackChunkName: "component---src-pages-facilitation-services-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-office-services-js": () => import("./../../../src/pages/office-services.js" /* webpackChunkName: "component---src-pages-office-services-js" */),
  "component---src-pages-privacystatement-js": () => import("./../../../src/pages/privacystatement.js" /* webpackChunkName: "component---src-pages-privacystatement-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-services-old-js": () => import("./../../../src/pages/services-old.js" /* webpackChunkName: "component---src-pages-services-old-js" */),
  "component---src-pages-termsandconditions-js": () => import("./../../../src/pages/termsandconditions.js" /* webpackChunkName: "component---src-pages-termsandconditions-js" */),
  "component---src-pages-theteam-js": () => import("./../../../src/pages/theteam.js" /* webpackChunkName: "component---src-pages-theteam-js" */),
  "component---src-pages-training-services-js": () => import("./../../../src/pages/training-services.js" /* webpackChunkName: "component---src-pages-training-services-js" */),
  "component---src-pages-web-services-js": () => import("./../../../src/pages/web-services.js" /* webpackChunkName: "component---src-pages-web-services-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */)
}

